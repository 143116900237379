const spritesUrl = new URL('evil-icons/assets/sprite.svg', import.meta.url)

function icon(name, { size = '', className = '' }) {
  const classes = `icon icon--${name} icon--${size} ${className}`.trim()

  var icon =  '<svg class="icon__cnt">' +
                `<use xlink:href='${spritesUrl}#${name}-icon' />` +
              '</svg>'

  var html =  '<div class="' + classes + '">' +
                wrapSpinner(icon, classes) +
              '</div>'

  return html
}

function wrapSpinner(html, klass) {
  if (klass.indexOf('spinner') > -1) {
    return '<div class="icon__spinner">' + html + '</div>'
  } else {
    return html
  }
}

/**
 *
 */
export default function renderIcons() {
    var icons = document.querySelectorAll('[data-icon]')

    for (var i = 0; i < icons.length; i++) {
      var currentIcon = icons[i]
      var name        = currentIcon.getAttribute('data-icon')
      var options = {
        className:  currentIcon.className,
        size:   currentIcon.getAttribute('data-size')
      }

      currentIcon.insertAdjacentHTML('beforebegin', icon(name, options))
      currentIcon.parentNode.removeChild(currentIcon)
    }
 }
